.cn-no-stores-content {
    text-align: center;
    color: #8c93ad;
    font-size: 14px;
    font-weight: 600;
}

.cn-add-store-button button {
    padding: 0px 1rem 0px 1rem;
}
