.cn-ml-as-radio-option {
    margin-left: calc(23px + 0.5rem);
}
.cn-section-header {
    border-bottom: 1px solid #d9dce9;
}
.cn-section-title {
    font-size: 24px;
    font-weight: 700;
}
.cn-hide-accordion-header > div {
    padding-top: 0;
}
.cn-hide-accordion-header
    > div:first-of-type
    button[aria-controls]:first-of-type {
    border-top: 0;
}
.cn-description-tracking-list {
    margin-left: 27px;
    margin-top: 0;
}
