/*Variables*/

:root {
    --soft-blue: #5e91eb;
    --gunmetal: #47535d;
    --cool-grey: #a4a8ab;
    --steel: #8a8e9b;
    --charcoal-grey: #3a444d;
    --pale-grey: #f8f9fa;
    --white: #ffffff;
    --silver: #ced4da;
    --steel-grey: #83878a;
    --pale-grey-two: #e9ecef;
    --dark: #23292f;
    --dark-light: lighten(var(--dark), 5%);
    --tangerine: #ff9600;
    --soft-tangerine: #fdf6e8;
    --red-orange: #ff3d00;
    --pale-olive-green: #aed581;
    --very-light-pink: #fbe9e7;
    --bluegrey: #7384a0;
    --pale-grey-three: #f1f3f5;
    --beige: #f1f8e9;
    --white-two: #e5e5e5;
    --input-background: #f9fafb;
    --lavender: #b8d0f2;
    --lavender-two: #b8d0f2;
    --aquamarine: #b1e0ec;
    --aquamarine-light: #ccebf3;
    --duck-egg-blue: #ecf2fb;
    --navy: #001439;
    --oldlace: #fcf8e3;
    --sienna: #8a6d3b;
    --olive-green: #83b04e;
    --wheat: #fbc77c;
    --robins-egg-blue: #aae2f2;
    --pale-olive: #b6d590;
    --light-salmon: #faa38b;
    --light-blue-grey: #bfb6e9;
    --text-primary-color: #313440;
    --text-secondary-color: #8c93ad;
}

/*-----------*/
.sidebar {
    height: 100%;
    width: 50px;
    background-color: #3a444d;
    display: block;
    z-index: 1002;
    position: fixed;
}

.app-body {
    background-color: #ffffff;
    min-height: 100vh;
    overflow: hidden;
}

.menu-item {
    color: #a4a8ab;
    display: inline-block;
    width: 30px;
    padding: 10px;
    text-align: center;
}

.menu-item:hover {
    color: #ffffff;
}

.menu-item.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #22292f;
}

.sidebar-expanded {
    width: 180px;
}

.backdrop {
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    position: fixed;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
}

.backdrop.visible {
    opacity: 1;
    right: 0;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.cn-paper {
    display: flex;
    padding: 16px;
    overflow: visible;
    flex-direction: column;
    margin-bottom: 50px;
}

.cn-paper.cn-tab {
    padding: 40px;
}

.page-header {
    font-family:
        Fira Sans,
        sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #47535d;
    margin-top: 52px;
    margin-bottom: 24px;
    padding: 0;
    border-bottom: none;
}

.page-header-container button,
.page-header-container a {
    float: right;
    margin-top: 52px;
}

.actions {
    white-space: nowrap;
}

.actions .MuiIconButton-root {
    padding: 5px 5px;
}

.filtered-list .MuiTableCell-sizeSmall {
    padding: 6px 1px 6px 1px;
}

.expired-discount time {
    color: red;
}

.hidden {
    display: none !important;
}

.MuiPaper-root.cn-tabs {
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #ced4da;
}

li.MuiTablePagination-menuItem,
li.MuiListItem-button {
    font-size: 12px;
    min-height: 40px;
}

.cn-option-title {
    font-weight: 600;
    font-size: 16px;
}

.cn-option-description {
    font-weight: 400;
    font-size: 16px;
    color: var(--text-secondary-color);
}
