.login-bg-container {
    background-position: 50%;
    background-size: cover;
    display: table;
    width: 100%;
    height: 100vh;
}

.login-container {
    display: table-cell;
    vertical-align: middle;
}

.error {
    color: #ff3d00;
}

.logo-container {
    padding: 30px;
    text-align: center;
}

.logo-title h1 {
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #47535d;
    margin: 0 0 8px;
    padding: 0;
    text-align: center;
}

.logo-title {
    padding: 0px 30px;
}
