.cn-spinner-container .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    z-index: 1000;
}

.cn-spinner-container {
    position: fixed;
    height: 100vh;
    z-index: 9999;
}
